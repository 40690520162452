<template> 
  <div class="dk-sign-list" style="user-select:none;">
    <!-- <span class="iconfont icongou" v-if="isSelfBuy" style="color:#fe9100"></span> -->
    <img v-if="isSelfBuy" src="../images/icon_gua.png" alt="">
    <span class="iconfont iconzi" v-if="isSelfSupport" style="color:#ad28d0"></span>
    <span class="iconfont iconwai" v-if="isOut" style="color:#1f5eab"></span>
    <img v-if="isTemporary" style="width: 13px;height: 12px" src="../images/temporary.png"/>
    <span v-if="isVip&&vipLevel">
      <span class="iconfont iconV1" v-if="vipLevel==1||vipLevel==4||vipLevel=='一级'"></span>
      <span class="iconfont iconV2" v-if="vipLevel==2||vipLevel==5||vipLevel=='二级'"></span>
      <span class="iconfont iconV3" v-if="vipLevel==3||vipLevel==6||vipLevel=='三级'"></span>
    </span>
    <span  v-if="!isVip&&vipLevel>0">
      <span class="iconfont iconV1" style="color:#ccc;" v-if="vipLevel==1||vipLevel==4"></span>
      <span class="iconfont iconV2" style="color:#ccc;" v-if="vipLevel==2||vipLevel==5"></span>
      <span class="iconfont iconV3" style="color:#ccc;" v-if="vipLevel==3||vipLevel==6"></span>
    </span>
    <img  src="../images/icon_new.png" v-if="isFirstTakerOrder" style="width:20px" title="新加入司机" alt="新加入司机" />
    <img v-if="isHasNoContainerExperience === true" src="../images/小白.png" title="没有集装箱经验司机" alt="没有集装箱经验司机" style="height: 17px;width: auto;"></img>
  </div>
</template>
<script>
// import PinyinMatch from "pinyin-match";
export default {
  name: "dk-sign-list",
  props:['isVip','isSelfSupport','isSelfBuy','isOut','vipLevel','isFirstTakerOrder','isTemporary', 'isHasNoContainerExperience'],
  computed:{
    // newValue: {
    //     get:function() {
    //         return this.value; 
    //     },
    //     set:function(value) {
    //         this.$emit('input', value);
    //     }
    // }
  },
  data() {
    return {
      restaurants: [],
      timeout: null,
      value:this.value
    };
  },
  mounted() {
    // console.log(this.isHasNoContainerExperience);
  },
  methods: {
       
  }
};
</script>
<style scoped>
.dk-sign-list{
  margin-left: 8px;
  display: inline-block;
  color: red;
}
  .dk-sign-list .iconfont{
    font-size: 12px;
  }
  .dk-sign-list img{
    width: 12px;
  }
</style>

