<template>
    <div id="incomplete-order-content"
        :class="type === 'index' || orderItem.containerChecked ? '' : 'order-content-unchecked'">
        <div :class="['incomplete-table', this.disabled ? 'disable' : '', type === 'index' ? '' : 'hover-red']">
            <el-table ref="singleTable" :data="orderItem.orderInfoList"
                :highlight-current-row="type === 'index' ? true : false" border @header-click="headClick"
                @row-click="rowClick" :row-class-name="tableRowClassName"
                @select="selectCheckbox" @select-all="handleSelectionAll" :header-row-style="headerRowStyleObj"
                :fit="false" tooltip-effect="light" empty-text="暂无未完成订单" style="width: 100%">
                <el-table-column property="index" v-if="type === 'index' && !hiddenColKeys.includes('index')"
                    type="index" align="center" min-width="30">
                </el-table-column>
                <el-table-column property="check" align="center"
                    v-if="type === 'checkbox' && !hiddenColKeys.includes('checkbox')" :selectable="() => !this.disabled"
                    type="selection" width="55">
                </el-table-column>
                <el-table-column property="desc" align="center"
                    v-if="type === 'checkbox' && !hiddenColKeys.includes('desc')" label="描述" width="120">
                    <template slot-scope="scope">
                        <span v-if="selectedDesc !== '' && getChecked(scope.row)" style="color:red">{{ selectedDesc
                            }}</span>
                        <span v-else-if="scope.row.taskStatusId === 'TASK_DROPPED'" style="color:red">
                            司机申请退单
                        </span>
                        <span v-else-if="scope.row.mergeStatus === '进行中'" style="color:#477DCD">
                            <div style="display: flex;align-items: center;">
                                <div class="loading">
                                    <div class="firstLoading"></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div style="margin-left: 5px;">进行中</div>
                            </div>
                        </span>
                        <span v-else-if="scope.row.mergeStatus === '已完成'" style="color:#868686">已完成</span>
                        <span v-else> {{ scope.row.mergeStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="planArrivalTime" v-if="!hiddenColKeys.includes('planArrivalTime')"
                    label="作业时间" show-overflow-tooltip align="center" min-width="100">
                    <template slot="header" slot-scope="scope">
                        <div>
                            <span>作业时间</span>
                            <el-popover placement="top-start" title="" width="400" trigger="hover"
                                popper-class="incomplete-order-popper">
                                <!-- <i slot="reference" class="el-icon-warning-outline" style="color: #E6A23C;"></i> -->
                                <svg slot="reference" t="1721876682788" class="icon" viewBox="0 0 1024 1024"
                                    version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2611"
                                    data-spm-anchor-id="a313x.search_index.0.i3.6e413a81ef8Elz" width="14" height="14">
                                    <path
                                        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                                        p-id="2612" fill="#111111"></path>
                                    <path
                                        d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7c0-19.7 12.4-37.7 30.9-44.8 59-22.7 97.1-74.7 97.1-132.5 0.1-39.3-17.1-76-48.3-103.3z"
                                        p-id="2613" fill="#111111"></path>
                                    <path d="M512 732m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" p-id="2614"
                                        fill="#111111"></path>
                                </svg>
                                <div class="incomplete-order-flex-column-start-start">
                                    <span>门点：做箱时间</span>
                                    <span>预提：要求完成时间</span>
                                    <span>二进：要求进港开始时间</span>
                                    <span>短驳（驳空疏港、驳重疏港、预提提空、仓库业务、二进进重、还空任务）：司机接单时间</span>
                                </div>
                            </el-popover>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        <span>{{ scope.row.planArrivalTime | filter_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="taskType" v-if="!hiddenColKeys.includes('taskType')" label="任务类型"
                    show-overflow-tooltip align="center" min-width="100">
                </el-table-column>
                <el-table-column property="currentLogAction" v-if="!hiddenColKeys.includes('currentLogAction')"
                    label="当前状态" show-overflow-tooltip align="center" min-width="80">
                </el-table-column>
                <el-table-column property="orderNo" v-if="!hiddenColKeys.includes('orderNo')" label="订单号" align="center"
                    min-width="130">
                    <template slot-scope="scope">
                        <div class="copyDiv flex-start-center">
                            <el-tooltip effect="dark" :content="scope.row.orderNo"
                                popper-class="incomplete-order-tooltip" placement="top">
                                <span class="span-line ellipsis-text" @click.stop="goPage('orderNo', scope.row)">{{
                                    scope.row.orderNo }}</span>
                            </el-tooltip>
                            <img v-if="scope.row.orderNo" @click.stop="copyClipboard(scope.row.orderNo)"
                                src="../images/icon_copy.png" class="copy-btn">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column property="number" v-if="!hiddenColKeys.includes('number')" label="箱号"
                    show-overflow-tooltip align="center" min-width="140">
                    <template slot-scope="scope">
                        <div class="copyDiv flex-start-center">
                            <el-tooltip effect="dark" :content="scope.row.number"
                                popper-class="incomplete-order-tooltip" placement="top">
                                <span v-if="scope.row.containerNoConfirmed || scope.row.number" class="ellipsis-text">{{
                                    scope.row.number | filter_number }}</span>
                            </el-tooltip>
                            <img v-if="scope.row.number" @click.stop="copyClipboard(scope.row.number)"
                                src="../images/icon_copy.png" class="copy-btn">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column property="containerSizeType" v-if="!hiddenColKeys.includes('containerSizeType')"
                    label="箱型" show-overflow-tooltip align="center" min-width="66">
                </el-table-column>
                <el-table-column property="pickupPointName" v-if="!hiddenColKeys.includes('pickupPointName')"
                    label="提箱点" show-overflow-tooltip align="center" min-width="100">
                </el-table-column>
                <el-table-column property="returnPointName" v-if="!hiddenColKeys.includes('returnPointName')"
                    label="还箱点" show-overflow-tooltip align="center" min-width="100">
                </el-table-column>
                <el-table-column property="doorAddress" v-if="!hiddenColKeys.includes('doorAddress')" label="门点地址"
                    show-overflow-tooltip align="center" min-width="240">
                </el-table-column>
                <el-table-column property="blNo" v-if="!hiddenColKeys.includes('blNo')" label="提单号" align="center"
                    min-width="130">
                    <template slot-scope="scope">
                        <div class="copyDiv flex-start-center">
                            <el-tooltip effect="dark" :content="scope.row.blNo" popper-class="incomplete-order-tooltip"
                                placement="top">
                                <span :class="`${isGoPage(scope.row.taskTypeId) ? 'span-line' : ''} ellipsis-text`"
                                    @click.stop="isGoPage(scope.row.taskTypeId) && goPage('blNo', scope.row)">{{
                                        scope.row.blNo }}</span>
                            </el-tooltip>
                            <img v-if="scope.row.blNo" @click.stop="copyClipboard(scope.row.blNo)"
                                src="../images/icon_copy.png" class="copy-btn">
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <dkTimeLine :nodes="nodes" :node-line-width="200" />
        </div>
    </div>
</template>
<script>
import dkTimeLine from 'dk-time-line'
import dkBlSearch from "dk-bl-search"
export default {
    name: 'dk-tasks-status-card',
    components: { dkTimeLine, dkBlSearch },
    props: {
        cardKey: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hiddenColKeys: {
            type: Array,
            default: () => []
        },
        // // 去掉
        // refund: {
        //     type: String,
        //     default: ''
        // },
        orderItem: {
            type: Object,
            default: () => ({
                orderInfoList: [],
                containerLogList: [],
                containerChecked: false
            })
        },
        selectedRows: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: 'index'
        },
        selectedDesc: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            nodes: [],
            modalBlSearch: {
                fade: false,
                blNo: '',
                number: ''
            },
            multipleSelection: [],
            selectedTaskIds: [],
            multipleSelectionTemp: [],
            headerRowStyleObj: {
                color: '#111'
            }
        }
    },
    watch: {
        multipleSelection(newValue) {
            if (newValue) {
                this.$emit('change', newValue, this.cardKey)
            }
        }
    },
    computed: {
        allRowChecked: function () {
            if (this.orderItem.orderInfoList.length === 0) return false
            return this.multipleSelection.length == this.orderItem.orderInfoList.length
        },
    },
    filters: {
        filter_time(date) {
            if (!date) {
                return ''
            }
            return dayjs(date).format('MM-DD HH:mm')
        },
        filter_number(data) {
            return data || ''
        }
    },
    mounted() {
        this.nodes = [];
        this.orderItem.containerLogList.forEach((list, index) => {
            this.nodes = this.nodes.concat(this.convertToTimelineNodes(list, index, this.orderItem.containerLogList.length));
        });
        if (this.type === 'index') {
            return
        } else {

            this.orderItem.orderInfoList.forEach((item) => {
                console.log(this.orderItem.containerChecked);
                if (item.taskStatusId === 'TASK_DROPPED' && this.orderItem.containerChecked) {
                    this.multipleSelection.push(item)
                    this.$refs.singleTable.toggleRowSelection(item, true)
                }
            })
        }
    },
    methods: {
        getChecked(val) {
            if (this.multipleSelection.some(item => {
                return item.tocId == val.tocId
            })) {
                return true
            }
            return false
        },
        handleSelectionAll(selection) {
            if (this.disabled) {
                return
            }
            let flag = false;
            selection.forEach((item) => {
                if (this.type === 'checkbox' && item.mergeStatus === '进行中' && item.taskStatusId !== 'TASK_DROPPED') {
                    flag = true
                }
            })
            if (flag) {
                this.$emit('info','已选中进行中的数据' )
            }
            if (selection.length === 0) {
                this.selectedTaskIds = [];
            } else {
                this.selectedTaskIds = Array.from(new Set(selection.map((r) => r.taskId)));
            }
            this.multipleSelection = selection;
        },
        selectCheckbox(selection, row) {
            this.onSelect(row);
        },
        rowClick(row) {
            if (this.type === 'index') {
                return
            }
            if (this.disabled) {
                this.$emit('info', '不可选中!')
                return
            }
            this.onSelect(row);
        },
        onSelect(row) {
            let refsElTable = this.$refs.singleTable; // 获取表格对象
            const selectedInfoItems = this.orderItem.orderInfoList.filter(c => c.taskId === row.taskId);  //找到选中的行
            const findIdx = this.selectedTaskIds.findIndex(id => id === row.taskId);
            // 取消选中
            if (findIdx >= 0) {
                this.selectedTaskIds.splice(findIdx, 1);
                this.multipleSelection = this.multipleSelection.filter(c => c.taskId !== row.taskId);
                selectedInfoItems.forEach((r) => {                    
                    refsElTable.toggleRowSelection(r, false);
                });
            }
            // 选中
            else {
                if (this.type === 'checkbox' && row.mergeStatus === '进行中' && row.taskStatusId !== 'TASK_DROPPED') {
                        this.$emit('info', '已选中进行中的数据')
                }
                this.selectedTaskIds.push(row.taskId);
                this.multipleSelection = this.multipleSelection.concat(selectedInfoItems);
                selectedInfoItems.forEach((r) => refsElTable.toggleRowSelection(r, true));
            }
        },

        tableRowClassName({ row }) {

            if (this.type === 'index') {
                const currentLogActionCode = row.currentLogActionCode
                const codeList = ['DROP_CONTAINER', 'RETURN']
                if (codeList.includes(currentLogActionCode)) {
                    return 'grey-row'
                } else if (row.selectFlag) {
                    return 'active-row';
                } else {
                    return '';
                }
            }
            if (row.taskStatusId === 'TASK_DROPPED') {
                return 'warning-row'
            }
            if (row.mergeStatus === '已完成') {
                return 'font-color'
            }
            let findRow = "";

            findRow = this.multipleSelection.find(c => c.tocId == row.tocId);
            if (findRow) {
                return 'warning-row' // elementUI 默认高亮行的class类 不用再样式了^-^,也可通过css覆盖改变背景颜色
            }
        },
        convertToTimelineNodes(logInfos, listIndex, totalLists) {
            return logInfos.map((info, index) => {
                const bottomText1 = info.scheduleActionName;
                const bottomText2 = !info.hasComplete && info.estimatedTime ? dayjs(info.estimatedTime).format('MM-DD HH:mm') : ''
                const titleText = bottomText2.length > 0 ? `预计${info.actionName.substring(1)}时间: ${bottomText2}` : ''
                let first = false;
                let last = false;

                if (index === 0 && listIndex !== 0) {
                    first = true;
                }
                if (index === logInfos.length - 1 && listIndex !== totalLists - 1) {
                    last = true;
                }

                return {
                    bottomText: bottomText1,
                    titleText: titleText,
                    active: info.hasComplete,
                    first: first,
                    last: last
                };
            });
        },
        copyClipboard(val) {
            _.util.copyClipboard(val);
            // this.$message.success('复制成功')
        },
        // 是否可跳转
        isGoPage(type) {
            const noGoPageTypeList = ['TASK_EMPTY_TRANS', 'TASK_SCHEDULED_ACCRUAL', 'TASK_GATE_IN_SHORT', 'TASK_EMPTY_TRANS_TURN', 'TASK_INTERIOR_EMPTY_TRANS', 'TASK_INTERIOR_GATE_IN', 'TASK_INTERIOR_HEAVY_TRANS', 'TASK_INTERIOR_EMPTY_TURN', 'TASK_HEAVY_TRANS', 'CONTAINER_NO_BOOK']
            return !noGoPageTypeList.includes(type)
        },
        // 跳转页面
        goPage(type, item) {
            if (type === 'orderNo') {
                window.location.hash = '#details-new?' + item.tocId + "&" + item.orderNo;
                this.$emit('hide-modal')
            } else {
                if (this.type === 'index') {
                    const modalBlSearch = {
                        fade: true,
                        blNo: item.blNo,
                        number: item.ctnNo
                    }
                    this.$eventBus.$emit('byDriverIncompleteOrder', modalBlSearch)
                    return
                }
                this.$emit('blNoClick', undefined, { blNo: item.blNo, number: item.ctnNo })
            }
        },
        headClick(column, event) {
            if (column.type === "selection" && this.disabled) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    },

}
</script>
<style scoped>
#incomplete-order-content {
    margin: 5px 0 3px;
    padding: 10px;
    border: 1px solid #2990EA;
    border-radius: 6px;
}

.order-content-unchecked {
    border: 1px solid #eeeeee !important;
}

#incomplete-order-content ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
    height: 10px;
}

#incomplete-order-content ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #CDCDCD;
    border-radius: 5px;
}

#incomplete-order-content ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

#incomplete-order-content .flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#incomplete-order-content .span-line {
    color: #477DCD;
    cursor: pointer;
}

#incomplete-order-content .copy-btn {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    cursor: pointer;
}

#incomplete-order-content .incomplete-table .ellipsis-text {
    width: calc(100% -21px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#incomplete-order-content ::v-deep .el-table .grey-row {
    color: #A1A1A1;
}

#incomplete-order-content ::v-deep .el-table .active-row {
    background: #DCEAFE;
    position: relative;
}

#incomplete-order-content ::v-deep .el-table .active-row:after {
    content: '';
    position: absolute;
    left: 1px;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 5px solid #2990EA;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    transform: translateY(-50%);
    z-index: 100000 !important;
}

#incomplete-order-content ::v-deep .el-table__row .el-table__cell {
    font-size: 13px;
    padding: 3px 0;
}

#incomplete-order-content ::v-deep .el-table__header .el-table__cell {
    padding: 4px 0;
}

#incomplete-order-content ::v-deep .el-table .cell {
    padding: 0 6px;
}

.incomplete-order-popper,
::v-deep .el-tooltip__popper {
    z-index: 100001 !important;
}

.incomplete-order-tooltip {
    z-index: 100001 !important;
    background-color: #fff !important;
    border: 1px solid #303133;
    color: #303133 !important;
}

.incomplete-order-flex-column-start-start {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.loading,
.loading>div {
    position: relative;
    box-sizing: border-box;
}

.loading {
    display: block;
    font-size: 0;
    color: #2990ea;
}

.loading.la-dark {
    color: #2990ea;
}

.loading>div {
    display: inline-block;
    float: none;
    background-color: #2990ea;
    border: 0 solid #2990ea;
}

.firstLoading {
    background-color: #2990ea !important;
    animation: none !important;
    opacity: 1 !important;
    height: 8px !important;
    width: 8px !important;
    transform: translate(100%, 100%);
}

.loading {
    width: 24px;
    height: 24px;
}

.loading>div {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    opacity: 0;
    animation: ball-scale-multiple 1.5s 0s linear infinite;
}

.loading>div:nth-child(1) {
    animation-delay: 0s;
}

.loading>div:nth-child(2) {
    animation-delay: 0.4s;
}

.loading>div:nth-child(3) {
    animation-delay: 0.6s;
}

.loading.la-sm {
    width: 16px;
    height: 16px;
}

.loading.la-sm>div {
    width: 16px;
    height: 16px;
}

.loading.la-2x {
    width: 64px;
    height: 64px;
}

.loading.la-2x>div {
    width: 64px;
    height: 64px;
}

.loading.la-3x {
    width: 96px;
    height: 96px;
}

.loading.la-3x>div {
    width: 96px;
    height: 96px;
}

@keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    5% {
        opacity: 0.75;
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.font-color {
    color: #477DCD;
}

#incomplete-order-content .disable ::v-deep .el-table th .el-checkbox__inner {
    background-color: #edf2fc !important;
    border-color: #DCDFE6 !important;
    cursor: not-allowed !important;
}

#incomplete-order-content .disable ::v-deep .el-table th .el-checkbox__inner::after {
    content: '' !important;
    border: none;
}

#incomplete-order-content ::v-deep .el-table .warning-row {
    background: #ffeaea;
}

#incomplete-order-content ::v-deep .el-table .font-color {
    color: #868686;
}

#incomplete-order-content .hover-red ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background-color: #ffeaea !important;
}
</style>